<template>
  <div>
    <b-modal
      ref="update-member-modal-1"
      centered
      hide-footer
      hide-header
      class="position-relative"
    >
      <div class="update-member-to-group d-flex justify-content-center align-content-center align-items-center modal1">
        <b-row
          class="d-block"
        >
          <div class="closediv">
            <b-button
              variant="transparent"
              class="closebtn"
              @click="hide()"
            >
              <feather-icon
                icon="XIcon"
                class="cursor-pointer"
                size="16"
              />
            </b-button>
          </div>
          <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center mt-2 mb-2">
            <span class="font-weight-bolder heading1">
              Update Permissions of Member
            </span>
          </div>
          <div class="d-inline-block m-1 d-flex justify-content-center align-content-center align-items-center m-1">
            <div
              id="form"
              class="form"
            >
              <validation-observer ref="updatePermissionOfMemberForm">
                <b-form
                  class="mt-2"
                  @submit.prevent
                >
                  <b-col class="mb-2">
                    <b-form-group
                      text-bolder
                      label="Role of Member"
                      class="label"
                    >
                      <b-form-select
                        v-model="isGroupOwner"
                        :disabled="!isOwner()"
                        :options="availableRoles"
                        :clearable="false"
                        class="mb-3"
                        @input="updateMemberStatus"
                      />
                    </b-form-group>
                  </b-col>
                  <!--permission checkboxes-->
                  <b-col class="mb-2">

                    <b-form-group
                      label="Select Permissions of Member:"
                      text-bolder
                      class="label"
                    >
                      <b-form-checkbox-group
                        id="checkbox-group-1"
                        v-model="member.permissions"
                        name="flavour-1"
                        class="demo-inline-spacing"
                      >
                        <b-form-group
                          v-if="getSelectedGroupLauncherPermissions"
                          label="Simi Launcher"
                          text-bolder
                          class="label"
                        >
                          <span
                            v-for="permission in getMemberPermissionsInSelectedGroup"
                            :key="permission.name"
                          >
                            <b-form-checkbox
                              v-if="filterFeatureNameForLauncher(permission.feature.name)"
                              :value="permission.feature.id"
                              :disabled="!isMember() || isTargetAdsOrCampaignAllow(permission.feature.id)"
                              class="mt-2"
                              @change="permissionChangeHandler($event, permission.feature.id, permission.feature.slug)"
                            >
                              <span class="text-capitalize">{{ filterFeatureNameForLauncher(permission.feature.name) }}</span>
                            </b-form-checkbox>
                          </span>
                        </b-form-group>
                      </b-form-checkbox-group>
                      <b-form-checkbox-group
                        id="checkbox-group-1"
                        v-model="member.permissions"
                        name="flavour-1"
                        class="demo-inline-spacing"
                      >
                        <b-form-group
                          v-if="getSelectedGroupIotPermissions"
                          label="Simi Smart"
                          text-bolder
                          class="label"
                        >
                          <span
                            v-for="permission in getMemberPermissionsInSelectedGroup"
                            :key="permission.name"
                          >

                            <b-form-checkbox
                              v-if="filterFeatureNameForIOT(permission.feature.name)"
                              :value="permission.feature.id"
                              :disabled="!isMember()"
                              size="8"
                              class="mt-2"
                            >
                              <span class="text-capitalize">{{ filterFeatureNameForIOT(permission.feature.name) }}</span>
                            </b-form-checkbox>
                          </span>
                        </b-form-group>
                      </b-form-checkbox-group>
                    </b-form-group>
                    <b-form
                      v-if="getSelectedGroupPowerSavingPermissions"
                      label="Power Saver"
                      text-bolder
                      class="label ml-2"
                    >
                      <b-form-checkbox
                        v-model="Checked"
                        disabled
                      >
                        Power Saver
                      </b-form-checkbox>
                    </b-form>
                  </b-col>
                  <!-- submit buttons -->
                  <div
                    class="form-group block mb-2 mx-3"
                  >
                    <b-button
                      block
                      variant="primary"
                      :disabled="spinner"
                      type="submit"
                      @click="updateMemberGroupDetails($event)"
                    >
                      <div
                        v-if="spinner"
                        class="spinner"
                      >
                        <b-spinner
                          small
                        />
                        <span class="sr-only">Loading...</span>
                      </div>
                      Update Permissions
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BForm,
  BFormGroup,
  VBModal,
  BRow,
  BCol,
  BSpinner,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormSelect,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import Vue from 'vue'
import MemberType from '@/common/enums/memberTypeEnum'
import { showErrorMessage, showToast } from '@/common/global/functions'
import store from '@/store'
import PackageType from '@/common/enums/packagesEnum'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BModal,
    BRow,
    BCol,
    BSpinner,
    BFormCheckbox,
    BFormCheckboxGroup,
    // validations
    ValidationObserver,
    BFormSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    updateMember: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      MemberType,
      spinner: false,
      spinnerOfRoleSwitch: false,
      isGroupOwner: 'Member',
      memberStatus: 'Member',
      availableRoles: ['Member', 'Super member'],
      member: {
        role: this.updateMember.role,
        permissions: [],
        userGroupMemberId: this.updateMember.id,
      },
      // validation
      required,
      PackageType,
      Checked: true,
    }
  },
  computed: {
    getSelectedGroupMember() {
      return this.$store.getters['user/getSelectedGroupMember']
    },
    deviceFeatureId() {
      const permission = this.getMemberPermissionsInSelectedGroup.find(object => object.feature.slug === 'devices')
      if (permission) {
        return permission.feature.id
      }
      return null
    },
    mediaFeatureId() {
      const permission = this.getMemberPermissionsInSelectedGroup.find(object => object.feature.slug === 'media')
      if (permission) {
        return permission.feature.id
      }
      return null
    },
    targetAdsFeatureId() {
      const permission = this.getMemberPermissionsInSelectedGroup.find(object => object.feature.slug === 'target-ads')
      if (permission) {
        return permission.feature.id
      }
      return null
    },
    bannerCampaignFeatureId() {
      const permission = this.getMemberPermissionsInSelectedGroup.find(object => object.feature.slug === 'banner-campaigns')
      if (permission) {
        return permission.feature.id
      }
      return null
    },
    getMemberPermissionsInSelectedGroup() {
      return this.getSelectedGroupMember ? this.getSelectedGroupMember.permissions : null
    },
    getMemberRoleInSelectedGroup() {
      return this.getSelectedGroupMember ? this.getSelectedGroupMember.role : null
    },
    getSelectedGroupAvailableApplications() {
      return this.$store.getters['user/getSelectedGroupAvailableApplications']
    },
    getSelectedGroupIotPermissions() {
      return this.getSelectedGroupAvailableApplications ? this.getSelectedGroupAvailableApplications.isSimiIot : null
    },
    getSelectedGroupLauncherPermissions() {
      return this.getSelectedGroupAvailableApplications ? this.getSelectedGroupAvailableApplications.isSimiLauncher : null
    },
    getSelectedGroupPowerSavingPermissions() {
      return this.getSelectedGroupAvailableApplications ? this.getSelectedGroupAvailableApplications.isSimiPowerSaver : null
    },
  },
  methods: {
    isTargetAdsOrCampaignAllow(permissionId) {
      if (permissionId === this.deviceFeatureId) {
        return this.member.permissions ? this.member.permissions.includes(this.bannerCampaignFeatureId) : null
      }
      if (permissionId === this.mediaFeatureId) {
        return this.member.permissions ? this.member.permissions.includes(this.bannerCampaignFeatureId) || this.member.permissions.includes(this.targetAdsFeatureId) : null
      }
      return null
    },
    permissionChangeHandler(value, permissionId, permissionSlug) {
      if (permissionSlug === 'banner-campaigns') {
        if (value.includes(permissionId)) {
          if (!this.member.permissions.includes(this.deviceFeatureId)) {
            this.member.permissions.push(this.deviceFeatureId)
          }
          if (!this.member.permissions.includes(this.mediaFeatureId)) {
            this.member.permissions.push(this.mediaFeatureId)
          }
        } else {
          const indexOfDevices = this.member.permissions.indexOf(this.deviceFeatureId)
          if (indexOfDevices !== -1) {
            this.member.permissions.splice(indexOfDevices, 1)
          }
          if (!this.member.permissions.includes(this.targetAdsFeatureId)) {
            const indexOfMedia = this.member.permissions.indexOf(this.mediaFeatureId)
            if (indexOfMedia !== -1) {
              this.member.permissions.splice(indexOfMedia, 1)
            }
          }
        }
      } else if (permissionSlug === 'target-ads') {
        if (value.includes(permissionId)) {
          if (!this.member.permissions.includes(this.mediaFeatureId)) {
            this.member.permissions.push(this.mediaFeatureId)
          }
        } else if (!this.member.permissions.includes(this.bannerCampaignFeatureId)) {
          const indexOfMedia = this.member.permissions.indexOf(this.mediaFeatureId)
          if (indexOfMedia !== -1) {
            this.member.permissions.splice(indexOfMedia, 1)
          }
        }
      }
    },
    isOwner() {
      return this.getMemberRoleInSelectedGroup === MemberType.OWNER
    },
    isMember() {
      return this.isGroupOwner === 'Member'
    },
    updateMemberStatus() {
      if (this.isGroupOwner === 'Super member') {
        this.member.role = MemberType.SUPER_MEMBER
        this.member.permissions = this.getMemberPermissionsInSelectedGroup.map(permission => permission.feature.id)
        this.memberStatus = 'Super Member'
      } else {
        this.member.role = MemberType.MEMBER
        this.member.permissions = []
        this.memberStatus = 'Member'
      }
    },
    show() {
      // eslint-disable-next-line no-unused-expressions,no-sequences
      this.member.role = this.updateMember.role
      this.member.userGroupMemberId = this.updateMember.id
      if (this.updateMember.role === MemberType.SUPER_MEMBER) {
        this.isGroupOwner = 'Super Member'
        this.memberStatus = 'Super Member'
        this.member.permissions = this.getMemberPermissionsInSelectedGroup.map(permission => permission.feature.id)
      } else {
        this.isGroupOwner = 'Member'
        this.memberStatus = 'Member'
        this.member.permissions = this.updateMember.permissions
      }
      this.$refs['update-member-modal-1'].show()
    },
    hide() {
      this.$refs['update-member-modal-1'].hide()
    },
    updateMemberGroupDetails() {
      if (this.getSelectedGroupPowerSavingPermissions) {
        this.member.permissions = [8]
      }
      this.$refs.updatePermissionOfMemberForm.validate().then(async success => {
        if (success) {
          if (this.member.permissions.length === 0) {
            showToast('Update Member Permissions', 'Please select permissions!', 'danger', 4000)
          } else if (this.member.email === null) {
            showToast('Update Member Permissions', 'Please enter correct email!', 'danger', 4000)
          } else {
            this.spinner = true
            await this.updatePermissionOfMember()
            this.spinner = false
            await this.$emit('getAllMembersOfGroup')
            await this.hide()
            this.member = {}
          }
        }
      })
    },
    async updatePermissionOfMember() {
      try {
        await this.$axios
          .put('user-has-group/update-member-details', this.member)
        await this.hide()
        showToast('Update Member Permissions', 'Member Permissions has been updated successfully!')
      } catch ({
        response: {
          data: {
            statusCode,
            message,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('Update Member Permissions', 'You are not authorized for this action!', 'danger')
          await store.dispatch('user/setAllUserGroupIfNotPresent', true)
          await this.$router.push('groups-listing')
        }
        await this.hide()
      }
    },
    filterFeatureNameForIOT(name) {
      if (name === 'Site') {
        return 'Site'
      }
      return null
    },
    filterFeatureNameForLauncher(name) {
      if (name !== 'Site') {
        return name
      }
      return undefined
    },
  },
}
</script>
<style src="vue-it-bigger/dist/vue-it-bigger.min.css"></style>
<style lang="scss">
.update-member-to-group {
  .search {
    .vs__dropdown-menu {
      max-height: 80px !important;
      overflow-y: auto !important;
      width: 320px !important;
    }
    .vs__dropdown-toggle{
      max-height: 60px !important;
      overflow-y: auto !important;
      width: 320px !important;
    }
  }
  #form {
    .custom-control {
      min-width: 145px !important;
      max-width: 145px !important;
    }
    .demo-inline-spacing {
      padding-inline: 40px !important;
    }
    .form-control-plaintext{
      font-weight: 600 !important;
    }

    .custom-checkbox .custom-control-label,{
      font-size: 0.9rem !important;
    }
    .custom-checkbox .custom-control-label::before, .custom-checkbox .custom-control-label::after, .custom-radio .custom-control-label::before, .custom-radio .custom-control-label::after,{
      width: 16px !important;
      height: 16px !important;
    }
  }
  .v-select .dropdown-toggle .clear {
    display: none;
  }
  .form{
    padding-inline: 10px;
  }
  .add{
    position: relative;
  }
  .head{
    font-size: 14px;
    line-height: 24px;
    color: #1f58b5;
    font-weight: 600;
    font-family: "Montserrat";
  }
  .spinner{
    text-align: center;
    z-index: 1070;
  }
  .switches {
    border-radius: 25px;
  }
}

</style>
